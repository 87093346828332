import React from 'react'
import MyTable from '../../../../core/my-table/my-table'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../interfaces/types/types'
import { IRate, ITeacher } from '../../../../../interfaces'
import { Link } from 'react-router-dom'
import PhoneNumber from '../../../../core/phone-number/phone-number'
import { Divider, Space } from 'antd'
import TeacherRate from '../../../teachers/teacher-rate/teacher-rate'
import { TEACHERS_FINANCES_KEY } from '../../../../../hooks/query/teachers'
import TableMobileTitle from '../../../../core/table-mobile-title/table-mobile-title'

interface props {
  teachers: ITeacher[]
}

const FinancesTeachersTable: React.FC<props> = ({ teachers }) => {
  const { t } = useTranslation(['translation', 'teachers'])

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      render: (text: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {text}
        </>
      ),
    },
    {
      title: t('firstName'),
      key: 'name',
      render: ({ id, name }: ITeacher) => (
        <>
          <TableMobileTitle title={t('firstName')} />
          <Link to={`/finances/teachers/${id}`}>{name}</Link>
        </>
      ),
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('phone')} />
          <PhoneNumber phone={text} />
        </>
      ),
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('createdAt')} />
          <PhoneNumber phone={text} />
        </>
      ),
    },
    {
      title: t('teacherRate'),
      dataIndex: 'rate',
      render: (rate: IRate) => (
        <>
          <TableMobileTitle title={t('teacherRate')} />
          <Space split={<Divider type={'vertical'} />}>
            <TeacherRate value={rate?.salary} sum={true} />
            <TeacherRate value={rate?.percentage} />
            <TeacherRate value={rate?.payment_per_student} sum={true} />
          </Space>
        </>
      ),
    },
  ]

  return <MyTable columns={columns} data={teachers} fetchingQueryKey={TEACHERS_FINANCES_KEY} />
}

export default FinancesTeachersTable
